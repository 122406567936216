.App {
  text-align: center;
}

.login_user {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-left: -200px;
  margin-top: -200px;

  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
}

.login_user h1 {
  text-align: center;
  font-size: 19pt;
  margin-bottom: 20px;
}

.login_user label, input {
  text-align: left;
  margin-left: 20px;
}

.login_user label{
  font-size: 12pt;
}

.campos {
  position: relative;

  background-color: #C0C0C0;
  border: none;
  border-radius: 1px;

  left: 49%;

  width: 350px;
  height: 35px;
  
  margin-left: -175px;
  margin-top: -17,5px;
}

.campos input{
  position: relative;
  outline: none;
  background-color: transparent;
  font-size: 13pt;
  border: none;
  border-radius: 1px;
  width: 300px;
}


.botao_submit{
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 350px;
  height: 35px;
  font-size: 13pt;
}

.botao_submit:active {
  outline: solid 1px #363636;
  box-shadow: 0px 0px 5px #363636;
  font-size: 14pt;
}

.recupSenha p{
  position: relative;
  margin-left: 20px;
}

.login-form {
  max-width: 100%;
  margin: 10px 10px 10px 10px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-box {
  padding: 20px;
}